import React from 'react';
import {useTranslation} from "react-i18next";
import './About.scss'


function About() {
    const {t} = useTranslation()

    return (
        <div className='about'>
            <p className='about-label'>{t('home.about.label')}</p>
            <p className='about-title'>{t('home.about.title')}</p>
            <p className='about-text'>{t('home.about.company')}</p>
            <p className='about-text'>{t('home.about.business')}</p>
        </div>
    );
}

export default About;
