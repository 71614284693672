import React from 'react';
import {useTranslation} from "react-i18next";
import ServiceContent from "./ServiceContent/ServiceContent";
import LooperPng from '../../../img/png/looper.png'
import './Service.scss'


function Service() {
    const {t} = useTranslation()

    return (
        <div className='service'>
            <img src={LooperPng} className='service-img' alt='service-img'/>
            <p className='service-label'>{t('home.service.label')}</p>
            <p className='service-title green-text'>{t('home.service.title')}</p>
            <ServiceContent />
        </div>
    );
}

export default Service;
