import React from 'react';
import {useTranslation} from "react-i18next";
import Link from "../../../../components/Link/Link";
import './FooterNav.scss'


function FooterNav() {
    const {t} = useTranslation()

    return (
        <div className='footer-nav'>
            <div className='footer-nav-menu'>
                <p className='footer-nav-menu-label'>{t('home.footer.company')}</p>
                <Link className='footer-nav-menu-link' text={t('menu.main')} scroll='.main'/>
                <Link className='footer-nav-menu-link' text={t('menu.about')} scroll='.about'/>
                <Link className='footer-nav-menu-link' text={t('menu.service')} scroll='.service'/>
                <Link className='footer-nav-menu-link' text={t('menu.footer')} scroll='.footer'/>
            </div>
            <div className='footer-nav-aml'>
                <p className='footer-nav-aml-label'>{t('home.footer.legal')}</p>
                <Link className='footer-nav-menu-link' text={t('home.footer.aml.privacy')} />
                <Link className='footer-nav-menu-link' text={t('home.footer.aml.terms')} />
            </div>
        </div>
    );
}

export default FooterNav;
