import React from 'react';
import './ServiceInfo.scss'


function ServiceInfo({title, text}) {
    return (
        <div className='service-info'>
            <p className='service-info-title'>{title}</p>
            <p className='service-info-text'>{text}</p>
        </div>
    );
}

export default ServiceInfo;
