import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Logo from "../../../../components/Logo/Logo";
import Input from "../../../../components/Input/Input";
import {isEmailValid} from "../../../../utils/validation";
import './Contacts.scss'


function Contacts() {
    const {t} = useTranslation()
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const updateEmail = (value) => {
        setEmail(value)

        if (!isEmailValid(value)) {
            setError(t('home.footer.invalidEmail'))
            return
        }

        setError('')
    }

    const sendEmail = () => !error && email && alert(`Send to email ${email}`)

    return (
        <div className='contacts'>
            <Logo />
            <p className='contacts-info'>
                {t('home.footer.info')}
            </p>
            <Input
                className='contacts-input'
                onChange={(value) => updateEmail(value)}
                value={email}
                error={error}
                placeholder={t('home.footer.email')}
                onClick={() => sendEmail()}
            />
            <p className='contacts-trademark'>
                {t('home.footer.trademark')}
            </p>
        </div>
    );
}

export default Contacts;
