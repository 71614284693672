import React from 'react';
import {useTranslation} from "react-i18next";
import WhyBlock from "./WhyBlock/WhyBlock";
import CardSvg from '../../../img/svg/card.svg'
import './Why.scss'


function Why() {
    const {t} = useTranslation()

    return (
        <div className='why'>
            <p className='why-title'>{t('home.why.title')}</p>
            <div className='why-content'>
                <WhyBlock className='why-content-flex' image={CardSvg} title={t('home.why.flexible.title')}/>
                <WhyBlock className='why-content-individual' image={CardSvg} title={t('home.why.individual.title')}/>
                <WhyBlock className='why-content-result' image={CardSvg} title={t('home.why.result.title')}/>
                <WhyBlock className='why-content-experience' image={CardSvg} title={t('home.why.experience.title')}/>
                <WhyBlock className='why-content-variety' image={CardSvg} title={t('home.why.variety.title')}/>
            </div>
        </div>
    );
}

export default Why;
