import React from 'react';
import Contacts from "./Contacts/Contacts";
import FooterNav from "./FooterNav/FooterNav";
import './Footer.scss'


function Footer() {
    return (
        <div className='footer'>
            <div className='footer-content'>
                <Contacts />
                <FooterNav />
            </div>
        </div>
    );
}

export default Footer;
