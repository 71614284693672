import React from 'react';
import {useTranslation} from "react-i18next";
import ArrowRightSvg from '../../../../../img/svg/arrowRight.svg';
import './ServiceSelector.scss'


function ServiceSelector({selected, onSelect, selectorList = []}) {
    const {t} = useTranslation()

    return (
        <div className='service-selector'>
            {selectorList.map((item, key) =>
                <p
                    key={key}
                    className={`service-selector-choice 
                    ${selected === key ? 'service-selector-choice-selected' : ''}`}
                    onClick={() => onSelect(key)}
                >
                    {t(`home.service.${item}.title`)}
                    {selected === key &&
                        <img className='service-selector-choice-arrow' src={ArrowRightSvg} alt='arrow-right'/>
                    }
                </p>
            )}
        </div>
    );
}

export default ServiceSelector;
