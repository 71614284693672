import React from 'react';
import './MainButton.scss'


function MainButton({children, className, onClick, isDisabled = false}) {
    return (
        <button className={`main-button ${className || ''}`} onClick={onClick} disabled={isDisabled}>
            {children}
        </button>
    );
}

export default MainButton;
