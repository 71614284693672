import React from 'react';
import LongArrowSvg from '../../img/svg/longArrowRight.svg'
import './Input.scss'


function Input({className, value, placeholder, onChange, type = 'text', onClick, error, disabled = false}) {

    const updateInput = (value) => onChange && onChange(value)

    return (
        <div className={`input ${className || ''}`}>
            <input
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={(e) => updateInput(e.target.value)}
            />
            <img className={`input-arrow`} src={LongArrowSvg} alt='longArrow' onClick={() => !disabled && onClick()}/>
            {error && <p className='input-error'>{error}</p>}
        </div>
    );
}

export default Input;
