import React from 'react';
import {useTranslation} from "react-i18next";
import CirclesPng from '../../../img/png/circles.png'
import StarPng from '../../../img/png/star.png'
import BgGradientPng from '../../../img/png/bgMainGradient.png'
import './Main.scss'


function Main() {
    const {t} = useTranslation()

    return (
        <div className='main'>
            <div className='main-circles'>
                <img className='main-circles-star' src={StarPng} alt="star"/>
                <img className='main-circles-image' src={CirclesPng} alt="circles"/>
            </div>
            <div className='main-gradient'>
                <img className='main-gradient-image' src={BgGradientPng} alt="bg"/>
            </div>
            <div className='main-text'>
                <p className='main-text-title'>
                    {t('home.main.title.everything')}
                    <span className='green-text'> {t('home.main.title.success')} </span>
                    <br/>
                    {t('home.main.title.need')}
                </p>
                <p className='main-text-info'>{t('home.main.text')}</p>
            </div>
        </div>
    );
}

export default Main;
