import React, {useEffect, useState} from 'react';
import Menu from "../../components/Menu/Menu";
import Main from "./Main/Main";
import About from "./About/About";
import Why from "./Why/Why";
import Service from "./Service/Service";
import Footer from "./Footer/Footer";
import {locationList, getScrollLocation} from "./scrollLocation";
import './Home.scss'


function Home() {
    const [location, setLocation] = useState(0)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        try {
            const found = locationList.indexOf(getScrollLocation())
            setLocation(found && found !== -1 ? found : 0)
        } catch (_) {

        }
    }

    return (
        <div className='home'>
            <Menu currentLocation={location} locationList={locationList} />
            <Main />
            <About />
            <Why />
            <Service />
            <Footer />
        </div>
    );
}

export default Home;
