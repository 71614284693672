import React from 'react';
import {useTranslation} from "react-i18next";
import Logo from "../Logo/Logo";
import Link from "../Link/Link";
import FilledButton from "../Buttons/FilledButton/FilledButton";
import EmptyButton from "../Buttons/EmptyButton/EmptyButton";
import './Menu.scss'


function Menu({currentLocation, locationList = []}) {
    const {t} = useTranslation()

    return (
        <div className='menu'>
            <div className='menu-content'>
                <Logo className='menu-content-logo'/>
                <div className='menu-content-nav'>
                    {locationList.map((item, key) => {
                        return <Link
                            key={key}
                            className={`menu-content-nav-link ${currentLocation === key ? 'text-700' : ''}`}
                            text={t(`menu.${item}`)}
                            scroll={`.${item}`}
                        />
                    })}
                </div>
                <div className='menu-content-actions'>
                    <EmptyButton text={t('menu.sign')} />
                    <FilledButton text={t('menu.register')} />
                </div>
            </div>
        </div>
    );
}

export default Menu;
