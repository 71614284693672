import React from 'react';
import LogoSvg from '../../img/logo.svg';


function Logo() {
    return (
        <div>
            <img src={LogoSvg} alt='logo' />
        </div>
    );
}

export default Logo;
