export const isEmailValid = (email) => {
    try {
        const value = String(email)
        if (!value) {
            return true
        }
        return (value && (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(String(value))))
    } catch (_) {
        return false
    }
}
