import React from 'react';
import './WhyBlock.scss';


function WhyBlock({className, image, title, text = 'Text?'}) {
    return (
        <div className={`why-block ${className || ''}`}>
            <div className='why-block-image'>
                <img src={image} alt='block'/>
            </div>
            <div className='why-block-title'>
                {title}
            </div>
            <div className='why-block-text'>
                {text}
            </div>
        </div>
    );
}

export default WhyBlock;
