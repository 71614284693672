import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ServiceSelector from "./ServiceSelector/ServiceSelector";
import ServiceInfo from "./ServiceInfo/ServiceInfo";
import './ServiceContent.scss'


const selectorList = ['register', 'create', 'it', 'account', 'concierge']

function ServiceContent() {
    const {t} = useTranslation()
    const [selected, setSelected] = useState(0)

    return (
        <div className='service-content'>
            <ServiceSelector selected={selected} onSelect={setSelected} selectorList={selectorList} />
            <ServiceInfo
                title={t(`home.service.${selectorList[selected]}.title`)}
                text={t(`home.service.${selectorList[selected]}.text`)}
            />
        </div>
    );
}

export default ServiceContent;
