import React from 'react';
import './Link.scss'


function Link({className, text, onClick, scroll}) {
    const processLink = () => {
        if (onClick) {
            onClick()
        }
        if (scroll) {
            try {
                const element = document.querySelectorAll(scroll)[0]
                const menu = document.querySelectorAll('.menu')[0]
                window.scrollTo({
                    top: element.offsetTop - menu.offsetHeight,
                    behavior: 'smooth',
                });
            } catch (_) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }

    return (
        <p className={`link ${className || ''}`} onClick={processLink}>{text}</p>
    );
}

export default Link;
