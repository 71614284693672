import React from 'react';
import MainButton from "../MainButton/MainButton";
import './FilledButton.scss'


function FilledButton({className, text, onClick, isDisabled}) {
    return (
        <MainButton className={`filled-button ${className || ''}`} onClick={onClick} isDisabled={isDisabled}>
            <p className='filled-button-text'>{text}</p>
        </MainButton>
    );
}

export default FilledButton;
