const MAIN = 'main'
const ABOUT = 'about'
const SERVICE = 'service'
const FOOTER = 'footer'

export const locationList = [MAIN, ABOUT, SERVICE, FOOTER]

const isBottom = () => {
    const fullScrollHeight = document.documentElement.scrollHeight
    const scrolled = window.scrollY
    const scrollWindowHeight = window.innerHeight

    return (fullScrollHeight - scrolled - scrollWindowHeight) === 0
}

export const getScrollLocation = () => {
    try {
        const main = document.querySelectorAll('.main')[0]
        const about = document.querySelectorAll('.about')[0]
        const service = document.querySelectorAll('.service')[0]
        const footer = document.querySelectorAll('.footer')[0]
        const menu = document.querySelectorAll('.menu')[0]

        const position = window.scrollY


        if (isBottom()) {
            return FOOTER
        }

        if (position <= main.offsetTop) {
            return MAIN
        }

        if (position >= about.offsetTop - menu.offsetHeight && position < service.offsetTop - menu.offsetHeight) {
            return ABOUT
        }

        if (position >= service.offsetTop - menu.offsetHeight && position < footer.offsetTop - menu.offsetHeight) {
            return SERVICE
        }
    } catch (_) {

    }
}
